import React from 'react';
import styled from 'styled-components';

import PageWrapper from 'components/Layout/PageWrapper';
import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Marketing = () => {
  return (
    <Root>
      <PageWrapper light>
        <h2 className='black'>Marketing Assets</h2>
        <Grid>
          <div>
            <h4 className='black'>website: solodistrict.com</h4>
            <Button href='https://solodistrict.com' text='visit the website' target='_blank' rel='noopener noreferrer' />
          </div>
          <div>
            <h4 className='black'>exclusive preview package</h4>
            <Button href='/downloads/marketing/solo-4_preview-package.zip' text='download preview package' download />
          </div>
          <div>
            <h4 className='black'>branding</h4>
            <Button href='/downloads/marketing/solo-4_branding-package.zip' text='download branding package' download/>
          </div>
          <div>
            <h4 className='black'>feature sheet</h4>
            <Button href='/downloads/marketing/solo-4_features-sheet.pdf' text='download feature sheet' target="_blank" rel="noreferrer noopener" />
          </div>
          <div>
            <h4 className='black'>taglines</h4>
            <ul className='black'>
              <li>Introducing the Final Tower at Solo District</li>
              <li>Completion of this Landmark Community in Brentwood</li>
            </ul>
          </div>
          <div>
            <h4 className='black'>social media</h4>
            <ul className='black'>
              <li>#solodistrict</li>
              <li>#appiadevelopments</li>
              <li>#solophase4</li>
              <li>#solo4</li>
            </ul>
          </div>
        </Grid>
      </PageWrapper>
    </Root>
  )
}

export default Marketing;

const Root = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${vw('width', 288, 700, 919)}
  ${vw('grid-row-gap', 40, 48)}
  ${vw('margin-top', 32, 44, 48)}
  h4 {
    text-transform: uppercase;
    ${vw('margin-bottom', 12, 16)}
  }
  ul {
    list-style: none;
    li {
      position: relative;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 1px;
        background-color: ${({ theme }) => theme.color.black};
        ${vw('margin-right', 14, 14)}
      }
    }
  }
  @media ${media.desktop} {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
`;