import React from 'react';
import styled from 'styled-components'

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Team = () => {
  return (
    <Root>
      <h2 className='white'>Team</h2>
      <Primary>
        <div>
          <img 
            src={require('src/assets/images/team/Appia_logo.svg')}
            alt='appia'  
            className='appia'
          />
          <p className='white'>
            Appia Developments is a dedicated team of Burnaby builders with deep roots in the community. Defined by over 30 years of experience, with multiple projects in the area and throughout the Lower Mainland, Appia continues to build on Jim Bosa’s legacy with reputation for integrity, honesty, quality, and customer satisfaction. Envisioned, designed, and delivered over more than 10 years, SOLO District is an iconic example of their commitment, passion, and expertise.
          </p>
        </div>
        <img src={require('src/assets/images/team/appia_image.jpg')} alt='rendering' className='rendering' />
      </Primary>
      <Secondary>
        <div>
          <img 
            src={require('src/assets/images/team/cda_logo.svg')}
            alt='cda'
            className='cda'
          />
          <h3 className='white'>CDA Architects</h3>
          <p className='white'>
            CDA is an award-winning firm dedicated to the highest level of design quality. CDA’s popular appeal has positioned the firm as one of North America’s leaders in multi-unit and high-rise residential communities. Respected for their proficiency in all facets of the design process, CDA tailors its solutions from start to finish, including concept and design development, construction drawings, and site services and more.
          </p>
        </div>
        <div>
          <img 
            src={require('src/assets/images/team/cristina_oberti_design_logo.png')}
            alt='cristina'
            className='cristina'
          />
          <h3 className='white'>Cristina Oberti <br/> Interior Design</h3>
          <p className='white'>
            Cristina Oberti Interior Design specializes in multi-unit residential developments and commercial designs. Whether the project is a modern high-rise, corporate office or marketing centre, they offer clients leading industry experience, extensive knowledge and a systematic design process. Each project has its own unique story and strategy - in response, the company creates interiors that convey a coherent yet stylish message.
          </p>
        </div>
        <div>
          <img 
            src={require('src/assets/images/team/magnum_logo.svg')}
            alt='magnum'
            className='magnum'
          />
          <h3 className='white'>Magnum Projects <br/> Marketing &amp; Sales</h3>
          <p className='white'>
            Magnum has been at the forefront of Vancouver luxury condo marketing since 1991, operating with a disciplined approach that ensures every home matches the needs and desires of today’s most discerning buyers. Led by George Wong, Magnum’s rigorous attention to detail, efficient and effective communication campaigns, and highly personalized sales programs appeal to a broad range of buyers, resulting in consistently strong sales and service.
          </p>
        </div>
      </Secondary>
    </Root>
  )
}

export default Team;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  ${vw('padding-top', 40, 64, 80)}
  ${vw('padding-bottom', 80, 120, 80)}
  h2 {
    ${vw('padding-left', 16, 34, 80)}
  }
`;

const Primary = styled.div`
  ${vw('margin-top', 32, 48)}
  ${vw('margin-bottom', 80, 80)}
  ${vw('padding-right', 0, 0, 56)}
  ${vw('padding-left', 0, 0, 56)}
  >div {
    ${vw('padding-right', 16, 34, 0)}
    ${vw('padding-left', 16, 34, 0)}
  }
  .appia {
    ${vw('width', 120, 168, 166)}
  }
  .rendering {
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    ${vw('width', 320, 720)}
    ${vw('margin-top', 80, 40, 0)}
    @media ${media.desktop} {

    }
  }
  p {
    ${vw('width', 288, 700, 405)}
    ${vw('margin-top', 24, 40)}
  }
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rendering {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const Secondary = styled.div`
  ${vw('padding-right', 16, 34, 80)}
  ${vw('padding-left', 16, 34, 80)}
  >div {
    ${vw('width', 288, 700, 402)}
    &:not(:last-of-type) {
      ${vw('margin-bottom', 48, 80, 0)} 
    }
    &:first-of-type {
      h3 {
        ${vw('margin-bottom', 16, 24, 56)}
      }
    }
  }
  h3 {
    text-transform: uppercase;
    ${vw('margin-bottom', 16, 24, 24)}
  }
  .cda {
    ${vw('width', 184, 278)}
  }
  .cristina {
    ${vw('width', 184, 278)}
  }
  .magnum {
    ${vw('width', 184, 278)}
  }
  @media ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    justify-content: space-between;
    >div {

    }
  }
`;