import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Footer = () => {
  return (
    <Root>
      <Container>
        <Logo src={require('src/assets/images/global/footer/logo_horizontal_black.svg')} alt='logo' />
        <Info>
          <Address>
            <p className='upper'>
              Presentation Centre
            </p>
            <p>
              <a href='https://goo.gl/maps/xhvrKWhVRoWcKVAc8' target='_blank' rel='noreferrer noopener' className='black'>
                104-4402 Skyline Drive <br/>
                Burnaby, BC, V5C 0M7
              </a>
            </p>
            <p>
              Open Daily By Appointment <br/>
              Only (Closed Fridays) <br/>
              12 - 5PM
            </p>
          </Address>
          <Contact>
            <p className='upper'>Contact Us</p>
            <p><span>T</span> <a href='tel:604.298.8800' className='black'>604.298.8800</a></p>
            <p><span>E</span> <a href='mailto:info@solodistrict.com' className='black'>info@solodistrict.com</a></p>
          </Contact>
          <SocialMedia>
            <p className='upper'>Follow Us</p>
            <div>
              <a href='https://www.facebook.com/SOLODistrict/' target='_blank' rel='noopener noreferrer'>
                <Icon
                  default={require('src/assets/images/menu/facebook_default.png')}
                  hover={require('src/assets/images/menu/facebook_hover.png')}
              />
              </a>
              <a href='https://www.instagram.com/liveatsolo/' target='_blank' rel='noopener noreferrer'>
                <Icon
                  default={require('src/assets/images/menu/instagram_default.png')}
                  hover={require('src/assets/images/menu/instagram_hover@2x.png')}
                />
              </a>
            </div>
          </SocialMedia>
        </Info>
        <Map>
          <a href='https://goo.gl/maps/xhvrKWhVRoWcKVAc8' target='_blank' rel='noreferrer noopener' className='black'><img src={require('src/assets/images/global/footer/map.jpg')} alt='map' /></a>
        </Map>
        <Partners>
          <a href='https://www.appiadev.com/' target='_blank' rel='noreferrer noopener'><img src={require('src/assets/images/global/footer/Appia_logo.svg')} alt='appia' /></a>
          <a href='https://magnumprojects.ca/' target='_blank' rel='noreferrer noopener'><img src={require('src/assets/images/global/footer/Magnum_logo.svg')} alt='magnum' /></a>
        </Partners>
      </Container>
      <Disclaimer>
        <p>
          © 2021 Appia. All Rights Reserved. <a href='https://www.bamdigital.com/' target='_blank' rel='noreferrer noopener' className='black'>Website by Bam Digital</a>
        </p>
        <p>
          This is not an offering for sale. Any such offering can only be made with a disclosure statement. All renderings and images are artist's representation only. 
          <br/>
          The developer reserves the right to make changes and modifications to the information herein without prior notice. E.&amp;O.E.
        </p>
      </Disclaimer>
    </Root>
  )
}

export default Footer;

const Root = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.yellow};
  ${vw('padding-top', 48)}
  ${vw('padding-right', 26, 54)}
  ${vw('padding-bottom', 20, 20)}
  ${vw('padding-left', 26, 54)}
  .upper {
    text-transform: uppercase;
    ${vw('margin-bottom', 16, 14, 16)}
  }
  @media ${media.desktop} {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Container = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
  ${vw('grid-row-gap', 40, 70)}
  @media ${media.tablet} {
    text-align: left;
  }
  @media ${media.desktop} {
    grid-template-columns: max-content max-content max-content;
    grid-column-gap: ${vwDesktop(80)};
  }
`;

const Logo = styled.img`
  ${vw('width', 140)}
  ${vw('height', 59)}
`;

const Info = styled.div`
  display: grid;
  justify-items: center;
  ${vw('grid-row-gap', 40, 0)}
  ${vw('grid-column-gap', 0, 40, 80)}
  @media ${media.tablet} {
    grid-template-columns: max-content max-content max-content;
  }
`;

const Address = styled.div`
  p {
    &:last-of-type {
      ${vw('margin-top', 8, 12)}
    }
  }
`;

const Contact = styled.div``;

const SocialMedia = styled.div`
  >div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    ${vw('grid-column-gap', 24)}
  }
`;

const Map = styled.div`
  ${vw('width', 228, 276)}
`;

const Partners = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
  ${vw('grid-column-gap', 16)}
  a {
    &:first-of-type {
      img {
        ${vw('width', 49, 70)}
      }
    }
    &:last-of-type {
      img {
        ${vw('width', 77, 110)}
      }
    }
  }
  @media ${media.desktop} {
    position: absolute;
    right: ${vwDesktop(70)};
    bottom: ${vwDesktop(32)};
  }
`;

const Disclaimer = styled.div`
  display: grid;
  ${vw('margin-top', 40, 70)}
  ${vw('grid-row-gap', 8)}
  p {
    text-align: center;
    font-weight: 300;
    ${vw('font-size', 10, 12)}
    ${vw('line-height', 14, 16)}
    a {
      display: block;
    }
    br {
      display: none;
    }
  }
  @media ${media.tablet} {
    p {
      a {
        display: inline-block;
      }
    }
  }
  @media ${media.desktop} {
    p {
      text-align: left;
      br {
        display: block;
      }
    }
  }
`;

const Icon = styled.div`
  background-image: ${props => `url(${props.default})`};
  background-size: cover;
  ${vw('width', 24, 32)}
  ${vw('height', 24, 32)}
  @media ${media.desktop} {
    transition: all 0.3s ease;
    &:hover {
      background-image: ${props => `url(${props.hover})`};
    }
  }
`;