import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Plan = (props) => {
  const { mobileDesktop, tablet, path } = props;
  return (
    <Root>
      <Container
        mobileDesktop={mobileDesktop}
        tablet={tablet}
      />
      <StyledButton href={path} text='download floorplans' download />
    </Root>
  )
}

export default Plan;

Plan.propTypes = {
  mobileDesktop: PropTypes.string,
  tablet: PropTypes.string,
  path: PropTypes.string,
}

const Root = styled.div``;

const Container = styled.div`
  background-image: ${props => `url(${props.mobileDesktop})`};
  background-size: cover;
  ${vw('width', 288, 340, 297)}
  ${vw('height', 454, 468, 468)}
  @media ${media.tablet} {
    background-image: ${props => `url(${props.tablet})`};
  }

  @media ${media.desktop} {
    background-image: ${props => `url(${props.mobileDesktop})`};
  }
`;

const StyledButton = styled(Button)`
  ${vw('width', 288, 340, 297)}
  ${vw('margin-top', 16, 16)}
`;