import React from 'react';

export default [
  {
    mobileDesktop: require('src/assets/images/plans/1bd_mobile_desktop.png'),
    tablet: require('src/assets/images/plans/1bd_tablet.png'), 
    path: '/downloads/floorplans/_1-bed+den_PDFs.zip',
  },
  {
    mobileDesktop: require('src/assets/images/plans/2b_mobile_desktop.png'),
    tablet: require('src/assets/images/plans/2b_tablet.png'), 
    path: '/downloads/floorplans/_2-bed_PDFs.zip',
  },
  {
    mobileDesktop: require('src/assets/images/plans/2bd_mobile_desktop.png'),
    tablet: require('src/assets/images/plans/2bd_tablet.png'), 
    path: '/downloads/floorplans/_2-bed+den_PDFs.zip',
  },
  {
    mobileDesktop: require('src/assets/images/plans/3b_mobile_desktop.png'),
    tablet: require('src/assets/images/plans/3b_tablet.png'), 
    path: '/downloads/floorplans/_3-bed_PDFs.zip',
  },
]