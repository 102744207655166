import React from 'react';
import styled from 'styled-components';

import PageWrapper from 'components/Layout/PageWrapper';
import Button from 'components/Button';
import Plan from '../elements/Plan';

import data from 'src/data/plans';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Floorplans = () => {
  return (
    <Root>
      <PageWrapper light>
        <Container>
          <h2 className='black'>Floorplans</h2>
          <Buttons>
            <Button href='/downloads/floorplans/_all-floorplans_PDFs.zip' text='download all floorplans' download />
          </Buttons>
        </Container>
        <Grid>
          {data.map((plan, i) => (
            <Plan
              mobileDesktop={plan.mobileDesktop}
              tablet={plan.tablet}
              path={plan.path}
              key={i}
            />
          ))}
        </Grid>
      </PageWrapper>
    </Root>
  )
}

export default Floorplans;

const Root = styled.div``;

const Container = styled.div`
  h2 {
    ${vw('margin-right', 0, 80)}
  }
  @media ${media.tablet} {
    display: flex;
    align-items: flex-end;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  ${vw('grid-row-gap', 16, 0)}
  ${vw('grid-column-gap', 0, 26, 24)}
  ${vw('grid-row-gap', 16, 0)}
  ${vw('margin-top', 40, 72, 0)}
  @media ${media.tablet} {
    grid-template-rows: auto;
    grid-template-columns: min-content min-content;
  }
`;

const Grid = styled.div`
  display: grid;
  ${vw('grid-row-gap', 40, 40, 64)}
  ${vw('margin-top', 40, 48, 78)}
  @media ${media.tablet} {
    grid-template-columns: repeat(2, min-content);
    justify-content: space-between;
  }
  @media ${media.desktop} {
    grid-template-columns: repeat(4, min-content);
  }
`;