import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const { href, text, yellow, dark } = props;
  return (
    <Root>
      <StyledLink href={href} yellow={yellow} dark={dark} {...props}>
        {text}
      </StyledLink>
    </Root>
  )
}

export default Button;

Button.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  dark: PropTypes.bool,
}

const Root = styled.div``;

const StyledLink = styled.a`
  display: inline-block;
  text-align: center;
  background-color: ${({ theme }) => theme.color.yellow};
  color: ${({ theme }) => theme.color.black};
  text-transform: uppercase;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  text-decoration: none !important;
  white-space: nowrap;
  ${vw('height', 30, 39)}
  ${vw('padding-top', 8, 11)}
  ${vw('padding-right', 16, 24)}
  ${vw('padding-bottom', 8, 11)}
  ${vw('padding-left', 16, 24)}
  ${vw('font-size', 12, 14)}
  ${vw('line-height', 14.4, 16.8)}
  ${vw('letter-spacing', 0.5, 1)}

  @media ${media.desktop} {
    transition: all 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.color.black};
      color: ${({ theme }) => theme.color.yellow};
    }
  }

  ${props => props.dark && css`
    background-color: ${({ theme }) => theme.color.black};
    color: ${({ theme }) => theme.color.yellow};
    &:hover {
      background-color: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.black};
    }
  `}

  ${props => props.yellow && css`
    &:hover {
      background-color: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.black};
    }
  `}
`;