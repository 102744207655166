module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  prerender: true,
  analytics: {
    gtag: '',
    gtmId: '',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Solo 4 Realtor Portal',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
  password: 'solo4vip'
};
