import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const PDF = () => {
  return (
    <Root>
      <Grid>
        <div>
          <h2 className='white'>Floorplates</h2>
          <Thumb src={require('src/assets/images/floorplans/thumb.png')} alt='thumb' />
          <StyledButton href='/downloads/floorplans/solo-4_floorplates.pdf' text='download floorplates' target="_blank" rel="noreferrer noopener" yellow />
        </div>
        <div>
          <h2 className='black'>Views</h2>
          <Thumb src={require('src/assets/images/views/thumb.png')} alt='thumb' />
          <StyledButton href='/downloads/views/solo-4_views.pdf' text='download views' target="_blank" rel="noreferrer noopener" />
        </div>
      </Grid>
    </Root>
  )
}

export default PDF;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;

const Grid = styled.div`
  display: grid;
  >div {
    ${vw('padding-top', 40, 64, 80)}
    ${vw('padding-right', 16, 34, 80)}
    ${vw('padding-bottom', 40, 64, 80)}
    ${vw('padding-left', 16, 34, 80)}
    &:first-of-type {
      border-bottom: 1px solid ${({ theme }) => theme.color.black};
      background-color: ${({ theme }) => theme.color.black};
    }
    h2 {
      ${vw('margin-bottom', 32, 48, 76)}
    }
  }
  @media ${media.desktop} {
    >div {
      display: flex;
      flex-direction: column;
      &:first-of-type {
        border-bottom: none;
        border-right: 1px solid ${({ theme }) => theme.color.black};
      }
    }
  }
`;

const Thumb = styled.img`
  border: 1px solid ${({ theme }) => theme.color.black};
  object-fit: cover;
  ${vw('width', 288, 297)}
  ${vw('margin-bottom', 16, 16)}
`;

const StyledButton = styled(Button)`
  ${vw('width', 288, 297)}
`;