import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Hero from './sections/Hero';
import Marketing from './sections/Marketing';
import Gallery from './sections/Gallery';
import Floorplans from './sections/Floorplans';
import PDF from './sections/PDF';
import Team from './sections/Team';

import appConfig from 'src/config/app.conf';

const Home = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('password') === appConfig.password) {
      setLoggedIn(true)
    }
  }, [])

  const handleChange = (e) => {
    sessionStorage.setItem('password', e.target.value);
    if (sessionStorage.getItem('password') === appConfig.password) {
      setLoggedIn(true);
    }
  }

  return (
    <Root>
      <Hero
        loggedIn={loggedIn}
        handleChange={handleChange}
      />
      {loggedIn &&
        <Content>
          <Marketing />
          <Gallery />
          <Floorplans />
          <PDF />
          <Team />
        </Content>
      }
    </Root>
  )
}

export default Home;

const Root = styled.div``;

const Content = styled.div``;