import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = (props) => {
  const { loggedIn, handleChange } = props;

  return (
    <Root loggedIn={loggedIn}>
      <img src={require('src/assets/images/pattern.svg')} alt='pattern' className='pattern' />

      <Container>
        <Title>
          <p className='strapline_hero white'>Welcome to the Solo 4 <br/> Realtor Portal</p>
          <h1 className='white'>The Completion of a <br/> Landmark Community</h1>
        </Title>

        <Login active={!loggedIn}>
          <p className='white'>Please enter your password to access:</p>
          <input 
            type='password'
            autoComplete='off'
            placeholder='Enter password'
            onChange={(e) => handleChange(e)}
          />
          <img src={require('src/assets/images/global/arrow.svg')} alt='arrow' />
        </Login>
        
        <Body active={loggedIn}>
          <p className='white'>
            Introducing the final tower at SOLO District, SOLO 4 - the last addition to North Burnaby’s most celebrated community. With the proven success of the first 3 towers at SOLO District, SOLO 4 concludes an ambitious undertaking envisioned by Appia Developments and brought to life by a talented team over a decade.
            <br/><br/>
            As a valued Magnum VIP Realtor, we offer you exclusive access to the SOLO 4 Realtor Portal. This portal provides access to all marketing materials that you may need in assisting you and your clients in buying a home at SOLO 4.
          </p>
        </Body>
      </Container>
      <img src={require('src/assets/images/global/Scroll-down_arrows.svg')} alt='arrow' className='arrow' />
    </Root>
  )
}

export default Hero;

Hero.propTypes = {
  loggedIn: PropTypes.bool,
  handleChange: PropTypes.func,
}

const animation = keyframes`
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  ${props => props.loggedIn ? vw('padding-top', 290, 590, 0) : vw('padding-top', 370, 744, 0)}
  ${vw('padding-bottom', 40, 40, 0)}

  .pattern {
    width: auto;
    position: absolute;
    ${vw('height', 320, 623)}
    ${vw('top', 104, 128, 145)}
    ${vw('left', -92, -176, -92)}
    @media ${media.desktop} {
      top: auto;
      bottom: ${vwDesktop(0)}
    }
  }
  
  .arrow {
    display: none;
    @media ${media.tablet} {
      display: ${props => props.loggedIn ? 'block' : 'none'};
      position: absolute;
      animation: ${animation} 2s infinite;
      transition: all 0.3s ease;
      ${vw('right', 0, 34, 82)}
      ${vw('bottom', 0, 54, 90)}
      ${vw('width', 34, 34)}
    }
  }
`;

const Container = styled.div`
  ${vw('padding-right', 16, 101, 0)}
  ${vw('padding-left', 16, 32, 0)}
  @media ${media.desktop} {
    width: ${vwDesktop(624)};
    position: absolute;
    top: 60%;
    left: ${vwDesktop(516)};
    transform: translateY(-50%);
  }
`;

const Title = styled.div`
  ${vw('margin-bottom', 16, 16, 24)}
  p {
    ${vw('margin-bottom', 8, 8, 16)}
  }
  @media ${media.tablet} {
    p {
      br {
        display: none;
      }
    }
  }
`;

const Login = styled.div`
  position: relative;
  overflow: hidden;
  opacity: ${props => props.active ? '1' : '0'};
  visibility: ${props => props.active ? 'visible' : 'hidden'};
  pointer-events: ${props => props.active ? 'all' : 'none'};
  ${props => props.active ? vw('max-height', 9999) : vw('max-height', 0)}
  transition: all 0.4s ease;
  ${vw('width', 288, 461)}
  p {
    ${vw('margin-bottom', 8, 17)}
  }
  input {
    width: 100%;
    background-color: ${({ theme}) => theme.color.black};
    outline: none;
    border: 1px solid ${({ theme }) => theme.color.yellow};
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    color: ${({ theme }) => theme.color.white};
    ${vw('font-size', 12, 14, 14)}
    ${vw('line-height', 20, 24, 24)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    ${vw('height', 36, 45)}
    ${vw('padding-right', 16, 16)}
    ${vw('padding-left', 16, 16)}
  }
  
  img {
    z-index: 1;
    position: absolute;
    bottom: 0;
    ${vw('bottom', 16, 19)}
    ${vw('left', 241, 400)}
    ${vw('width', 32, 45)}
  }
`;

const Body = styled.div`
  overflow: hidden;
  opacity: ${props => props.active ? '1' : '0'};
  visibility: ${props => props.active ? 'visible' : 'hidden'};
  pointer-events: ${props => props.active ? 'all' : 'none'};
  ${props => props.active ? vw('max-height', 9999) : vw('max-height', 0)}
  transition: all 0.4s ease;
  transition-delay: 0.4s;

  ${vw('margin-bottom', 16, 16, 24)}
`;