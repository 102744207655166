import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Assets = (props) => {
  const { title, assets } = props;
  return (
    <Root>
      <h4 className='yellow'>{title}</h4>
      <Grid>
      {assets.map((asset, i) => (
        <Card>
          <img src={asset.src} alt='' />
          <Button
            href={asset.download}
            text='download jpg'
            download
            yellow
          />
        </Card>
      ))}
      </Grid>
    </Root>
  )
}

export default Assets;

const Root = styled.div`
  h4 {
    font-weight: normal;
    ${vw('margin-bottom', 18, 22, 24)}
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  ${vw('grid-column-gap', 29, 29)}
  ${vw('grid-row-gap', 40, 40)}
  ${vw('width', 288, 624, 1276)}
  @media ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${media.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Card = styled.div`
  img {
    object-fit: cover;
    object-position: center;
    ${vw('width', 288, 297)}
    ${vw('height', 288, 297)}
    ${vw('margin-bottom', 8, 16, 16)}
  }
  a {
    width: 100%;
  }
`;