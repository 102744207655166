import { createGlobalStyle } from 'styled-components';
import theme from './theme';

import vw from 'src/styles/utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  iframe {
    display: none;
  }

  body {
    font-family: 'Roboto';
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .white {
    color: ${theme.color.white};
  }

  .yellow {
    color: ${theme.color.yellow};
  }

  .black {
    color: ${theme.color.black};
  }

  .darkGrey {
    color: ${theme.color.darkGrey};
  }

  .strapline_hero {
    display: block;
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    ${vw('font-size', 12, 20)}
    ${vw('line-height', 14.4, 24)}
  }

  h1 {
    font-family: 'Gotham';
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    ${vw('font-size', 20, 44,)}
    ${vw('line-height', 24, 52.8)}
    ${vw('letter-spacing', 1, 1)}
  }

  h2 {
    font-family: 'Gotham';
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    ${vw('font-size', 20, 40)}
    ${vw('line-height', 24, 48)}
    ${vw('letter-spacing', 1, 1, 1)}
  }

  h3 {
    font-family: 'Gotham';
    font-weight: 500;
    font-style: normal;
    ${vw('font-size', 16, 24)}
    ${vw('line-height', 20, 28.8)}
  }

  h4 {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    text-transform: uppercase;
    ${vw('font-size', 14, 18)}
    ${vw('line-height', 16.8, 32)}
    ${vw('letter-spacing', 2, 3)}
  }

  p, li {
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    ${vw('font-size', 12, 14, 14)}
    ${vw('line-height', 20, 24, 24)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
  }
`;
