import React from 'react';

export default [
  {
    title: 'building',
    assets: [
      {
        src: require('src/assets/images/gallery/building/01_building_aerial-1.jpg'),
        download: '/downloads/gallery/building/01_building_aerial-1.jpg',
      },
      {
        src: require('src/assets/images/gallery/building/02_building_community.jpg'),
        download: '/downloads/gallery/building/02_building_community.jpg',
      },
      {
        src: require('src/assets/images/gallery/building/03_building_exterior-view.jpg'),
        download: '/downloads/gallery/building/03_building_exterior-view.jpg',
      },
      {
        src: require('src/assets/images/gallery/building/04_building_aerial-2.jpg'),
        download: '/downloads/gallery/building/04_building_aerial-2.jpg',
      },
    ],
  },
  {
    title: 'amenities',
    assets: [
      {
        src: require('src/assets/images/gallery/amenities/01_amenities_business-center.jpg'),
        download: '/downloads/gallery/amenities/01_amenities_business-center.jpg',
      },
      {
        src: require('src/assets/images/gallery/amenities/02_amenities_gym.jpg'),
        download: '/downloads/gallery/amenities/02_amenities_gym.jpg',
      },
      {
        src: require('src/assets/images/gallery/amenities/03_amenities_media-room.jpg'),
        download: '/downloads/gallery/amenities/03_amenities_media-room.jpg',
      },
      {
        src: require('src/assets/images/gallery/amenities/04_amenities_private-dining.jpg'),
        download: '/downloads/gallery/amenities/04_amenities_private-dining.jpg',
      },
      {
        src: require('src/assets/images/gallery/amenities/05_amenities_roof-deck.jpg'),
        download: '/downloads/gallery/amenities/05_amenities_roof-deck.jpg',
      },
    ],
  },
  {
    title: 'interiors',
    assets: [
      {
        src: require('src/assets/images/gallery/interiors/01_interiors_balcony-dark.jpg'),
        download: '/downloads/gallery/interiors/01_interiors_balcony-dark.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/02_interiors_balcony-light.jpg'),
        download: '/downloads/gallery/interiors/02_interiors_balcony-light.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/03_interiors_bedroom.jpg'),
        download: '/downloads/gallery/interiors/03_interiors_bedroom.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/04_interiors_bathroom-dark.jpg'),
        download: '/downloads/gallery/interiors/04_interiors_bathroom-dark.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/05_interiors_bathroom-light.jpg'),
        download: '/downloads/gallery/interiors/05_interiors_bathroom-light.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/06_interiors_kitchen-dark.jpg'),
        download: '/downloads/gallery/interiors/06_interiors_kitchen-dark.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/07_interiors_kitchen-light.jpg'),
        download: '/downloads/gallery/interiors/07_interiors_kitchen-light.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/08_interiors_living-light.jpg'),
        download: '/downloads/gallery/interiors/08_interiors_living-light.jpg',
      },
      {
        src: require('src/assets/images/gallery/interiors/09_interiors_living-dark.jpg'),
        download: '/downloads/gallery/interiors/09_interiors_living-dark.jpg',
      },
    ],
  }
]