import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const PageWrapper = (props) => {
  const { children, light } = props;
  return (
    <Root light={light}>
      {children}
    </Root>
  )
}

export default PageWrapper;

const Root = styled.div`
  background-color: ${props => props.light ? props.theme.color.white : props.theme.color.black};
  ${vw('padding-top', 40, 64, 80)}
  ${vw('padding-right', 16, 34, 80)}
  ${vw('padding-bottom', 40, 64, 80)}
  ${vw('padding-left', 16, 34, 80)}
`;