import React from 'react';
import styled from 'styled-components';

import PageWrapper from 'components/Layout/PageWrapper';
import Button from 'components/Button';
import Assets from '../elements/Assets';

import data from 'src/data/gallery';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Gallery = () => {
  return (
    <Root>
      <PageWrapper>
        <Container>
          <h2 className='white'>Gallery</h2>
          <Buttons>
            <Button href='/downloads/gallery/solo-4_all-images.zip' text='download all images' download yellow />
          </Buttons>
        </Container>
        <Grid>
          <Assets
            title={data[0].title}
            assets={data[0].assets}
          />
          <Assets
            title={data[1].title}
            assets={data[1].assets}
          />
          <Assets
            title={data[2].title}
            assets={data[2].assets}
          />
        </Grid>
      </PageWrapper>
    </Root>
  )
}

export default Gallery;

const Root = styled.div``;

const Container = styled.div`
  h2 {
    ${vw('margin-right', 0, 125)}
  }
  @media ${media.tablet} {
    display: flex;
    align-items: flex-end;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  ${vw('grid-row-gap', 16, 0)}
  ${vw('grid-column-gap', 0, 26, 24)}
  ${vw('grid-row-gap', 16, 0)}
  ${vw('margin-top', 40, 72, 0)}
  @media ${media.tablet} {
    grid-template-rows: auto;
    grid-template-columns: min-content min-content;
  }
`;

const Grid = styled.div`
  display: grid;
  ${vw('grid-row-gap', 40, 40, 64)}
  ${vw('margin-top', 40, 48, 78)}
`;